/** @jsx jsx */
import React, { memo } from "react"
import { jsx, Box, Button, Label } from "theme-ui"
import { Row, Col } from "react-flexbox-grid"
import Switch from "react-switch"

import CurrencyInput from "./currency-input"
import PercentInput from "./percent-input"
import TermInput, { Term } from "./term-input"
import MonthInput, { Month } from "./month-input"
import RadioGroup from "./radio-group"
import HelpContainer from "./help"
import { CalcRequest, defaultCalcReq } from "./calc-request"
import styled from "utils/styled"
import today, { todayMonth } from "./today"

const creditTypeOptions = [
  { value: `annuity`, label: `Аннуитетный` },
  { value: `differential`, label: `Дифференцированный` },
]

const StyledLabel = memo(styled(Label)`
  margin-bottom: ${p => p.theme.space[1]};
`)

const FormRow = styled(Row)`
  margin-bottom: ${p => p.theme.space[3]};
`

const CurrencyInputRow = memo(
  ({ name, label, helpText, value, onInvalidInput, onValueChange, onStartCalculating }: any) => (
    <FormRow>
      <Col xs={12}>
        <StyledLabel htmlFor={name}>
          {helpText ? <HelpContainer text={helpText}>{label}</HelpContainer> : label}
        </StyledLabel>
        <CurrencyInput
          name={name}
          value={value}
          onInvalidInput={onInvalidInput}
          onValueChange={onValueChange}
          onStartCalculating={onStartCalculating}
        />
      </Col>
    </FormRow>
  )
)

const PercentInputRow = memo(({ name, label, value, onInvalidInput, onValueChange, onStartCalculating }: any) => (
  <FormRow>
    <Col xs={12}>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <PercentInput
        name={name}
        value={value}
        onInvalidInput={onInvalidInput}
        onValueChange={onValueChange}
        onStartCalculating={onStartCalculating}
      />
    </Col>
  </FormRow>
))

const TermInputRow = memo(({ name, label, initialTerm, onTermChange }: any) => (
  <FormRow>
    <Col xs={12}>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <TermInput name={name} initialTerm={initialTerm} onTermChange={onTermChange} />
    </Col>
  </FormRow>
))

const ShowMoreParamsButton = memo(({ showMoreParams, onChangeShowMoreParams }: any) => (
  <FormRow>
    <Button onClick={onChangeShowMoreParams} sx={{ ml: 2 }} variant="secondary">
      {showMoreParams ? `Меньше` : `Больше`} параметров
    </Button>
  </FormRow>
))

type Props = {
  calcReq: CalcRequest
  needInflation: boolean

  onChange: (newData: object) => void
  onInvalidInput: () => void
  onStartCalculating: () => void
}

type State = {
  showMoreParams: boolean
}

class CalculatorForm extends React.PureComponent<Props, State> {
  private initialTerm: Term

  constructor(props: Props) {
    super(props)
    const { calcReq } = props
    this.state = {
      showMoreParams:
        calcReq.needCommissions !== defaultCalcReq.needCommissions ||
        (calcReq.needCommissions &&
          (calcReq.onetimeComissionPercent !== defaultCalcReq.onetimeComissionPercent ||
            calcReq.yearlyComissionPercent !== defaultCalcReq.yearlyComissionPercent)) ||
        calcReq.startMonth !== defaultCalcReq.startMonth ||
        calcReq.startYear !== defaultCalcReq.startYear,
    }
    this.initialTerm = {
      years: Math.floor(calcReq.termMonths / 12),
      additionalMonths: calcReq.termMonths % 12,
    }
  }

  private onChangeShowMoreParams = () => {
    this.setState({
      showMoreParams: !this.state.showMoreParams,
    })
  }
  private onSubmit = (e: any) => {
    e.preventDefault()
  }
  private onChangePrice = (v: number) => this.props.onChange({ price: v })
  private onChangeDownPayment = (v: number) => this.props.onChange({ downPayment: v })
  private onChangeInterestRate = (v: number) => this.props.onChange({ interestRate: v })
  private onTermChange = (v: Term) => this.props.onChange({ termMonths: v.years * 12 + v.additionalMonths })
  private onCreditTypeChange = (v: string) => this.props.onChange({ isAnnuity: v === "annuity" })
  private onStartMonthChange = (v: Month) => this.props.onChange({ startMonth: v.num, startYear: v.year })
  private onNeedCommissionsChange = (v: boolean) => this.props.onChange({ needCommissions: v })
  private onOnetimeCommissionChange = (v: number) => this.props.onChange({ onetimeComissionPercent: v })
  private onYearlyCommissionChange = (v: number) => this.props.onChange({ yearlyComissionPercent: v })

  private onChangeYearlyInflationPercent = (v: number) => this.props.onChange({ yearlyInflationPercent: v })

  private onMonthlyRentPaymentChange = (v: number) => this.props.onChange({ monthlyRentPayment: v })
  private onYearlyRentIncreasePercentChange = (v: number) => this.props.onChange({ yearlyRentIncreasePercent: v })
  private onYearlyHomePriceIncreasePercentChange = (v: number) =>
    this.props.onChange({ yearlyHomePriceIncreasePercent: v })
  private onBankRateChange = (v: number) => this.props.onChange({ bankRate: v })
  private onUseIISChange = (v: boolean) => this.props.onChange({ useIIS: v })
  private onMonthlyServicesPaymentChange = (v: number) => this.props.onChange({ monthlyServicesPayment: v })
  private onRepairsTotalChange = (v: number) => this.props.onChange({ repairsTotal: v })

  private helpProfitability = `Cтавка доходности на накапливаемый капитал (например, банковская ставка по депозитам).`

  private renderRentCompareForm() {
    if (!this.props.calcReq.needCompareWithRent) {
      return null
    }
    return [
      <CurrencyInputRow
        key="monthlyRentPayment"
        name="monthlyRentPayment"
        label="Ежемесячная аренда"
        helpText="Ежемесячная плата за съем квартиры, включая услуги ЖКХ, если вы за них платите."
        value={this.props.calcReq.monthlyRentPayment}
        onInvalidInput={this.props.onInvalidInput}
        onValueChange={this.onMonthlyRentPaymentChange}
        onStartCalculating={this.props.onStartCalculating}
      />,
      <FormRow key="yearlyRentIncreasePercent">
        <Col xs={12}>
          <StyledLabel htmlFor="yearlyRentIncreasePercent">
            <HelpContainer text="Процент ежегодного роста стоимости аренды.">Удорожание аренды</HelpContainer>
          </StyledLabel>
          <PercentInput
            name="yearlyRentIncreasePercent"
            value={this.props.calcReq.yearlyRentIncreasePercent}
            onInvalidInput={this.props.onInvalidInput}
            onValueChange={this.onYearlyRentIncreasePercentChange}
            onStartCalculating={this.props.onStartCalculating}
          />
        </Col>
      </FormRow>,
      <FormRow key="yearlyHomePriceIncreasePercent">
        <Col xs={12}>
          <StyledLabel htmlFor="yearlyHomePriceIncreasePercent">
            <HelpContainer text="Процент ежегодного роста стоимости квартиры.">Удорожание квартиры</HelpContainer>
          </StyledLabel>
          <PercentInput
            name="yearlyHomePriceIncreasePercent"
            value={this.props.calcReq.yearlyHomePriceIncreasePercent}
            onInvalidInput={this.props.onInvalidInput}
            onValueChange={this.onYearlyHomePriceIncreasePercentChange}
            onStartCalculating={this.props.onStartCalculating}
          />
        </Col>
      </FormRow>,
      <FormRow key="bankRate">
        <Col xs={12}>
          <StyledLabel htmlFor="bankRate">
            <HelpContainer text={this.helpProfitability}>Доходность вложений</HelpContainer>
          </StyledLabel>
          <PercentInput
            name="bankRate"
            value={this.props.calcReq.bankRate}
            onInvalidInput={this.props.onInvalidInput}
            onValueChange={this.onBankRateChange}
            onStartCalculating={this.props.onStartCalculating}
          />
        </Col>
      </FormRow>,
      <FormRow key="useIIS">
        <Col xs={12}>
          <StyledLabel htmlFor="useIIS">
            <HelpContainer text="Используется индивидуальный инвестиционный счет (ИИС) - это дает +13% к ежегодным накоплениям до 400к руб/год.">
              Есть ИИС
            </HelpContainer>
          </StyledLabel>
          <Switch onChange={this.onUseIISChange} checked={this.props.calcReq.useIIS} />
        </Col>
      </FormRow>,
      <FormRow key="monthlyServicesPayment">
        <Col xs={12}>
          <StyledLabel htmlFor="monthlyServicesPayment">
            <HelpContainer text="Ежемесячная оплата услуг ЖКХ за ипотечную квартиру.">
              Услуги ЖКХ в ипотеке
            </HelpContainer>
          </StyledLabel>
          <CurrencyInput
            name="monthlyServicesPayment"
            value={this.props.calcReq.monthlyServicesPayment}
            onInvalidInput={this.props.onInvalidInput}
            onValueChange={this.onMonthlyServicesPaymentChange}
            onStartCalculating={this.props.onStartCalculating}
          />
        </Col>
      </FormRow>,
      <FormRow key="repairsTotal">
        <Col xs={12}>
          <StyledLabel htmlFor="repairsTotal">
            <HelpContainer text="Сколько будет потрачено на ремонт ипотечной квартиры в течение ипотечного срока.">
              Ремонт в ипотеке
            </HelpContainer>
          </StyledLabel>
          <CurrencyInput
            name="repairsTotal"
            value={this.props.calcReq.repairsTotal}
            onInvalidInput={this.props.onInvalidInput}
            onValueChange={this.onRepairsTotalChange}
            onStartCalculating={this.props.onStartCalculating}
          />
        </Col>
      </FormRow>,
    ]
  }

  private renderCommissionForm() {
    if (!this.props.calcReq.needCommissions) {
      return null
    }

    return [
      <FormRow key="onetimeComissionPercent">
        <Col xs={12}>
          <StyledLabel htmlFor="onetimeComissionPercent">
            <HelpContainer text="Комиссия банку, оплата риэлтора, аренда банковской ячейки и тд. В % от размера кредита.">
              Единоразовая комиссия
            </HelpContainer>
          </StyledLabel>
          <PercentInput
            name="onetimeComissionPercent"
            value={this.props.calcReq.onetimeComissionPercent}
            onInvalidInput={this.props.onInvalidInput}
            onValueChange={this.onOnetimeCommissionChange}
            onStartCalculating={this.props.onStartCalculating}
          />
        </Col>
      </FormRow>,
      <FormRow key="yearlyComissionPercent">
        <Col xs={12}>
          <StyledLabel htmlFor="yearlyComissionPercent">
            <HelpContainer text="Страхование жизни, квартиры, титула и тд. В процентах от остатка задолженности.">
              Ежегодная комиссия
            </HelpContainer>
          </StyledLabel>
          <PercentInput
            name="yearlyComissionPercent"
            value={this.props.calcReq.yearlyComissionPercent}
            onInvalidInput={this.props.onInvalidInput}
            onValueChange={this.onYearlyCommissionChange}
            onStartCalculating={this.props.onStartCalculating}
          />
        </Col>
      </FormRow>,
    ]
  }

  private renderMoreParamsForm() {
    if (!this.state.showMoreParams) {
      return null
    }

    return (
      <div>
        <FormRow>
          <Col xs={12}>
            <StyledLabel htmlFor="creditType">
              <HelpContainer text="В аннуитетных платежах месячный платеж постоянен, в дифференциальных - ежемесячно уменьшается. Дифференциальные платежи встречаются редко">
                Вид платежей
              </HelpContainer>
            </StyledLabel>
            <RadioGroup
              options={creditTypeOptions}
              activeValue={this.props.calcReq.isAnnuity ? "annuity" : "differential"}
              name="creditType"
              onChange={this.onCreditTypeChange}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col xs={12}>
            <StyledLabel htmlFor="startMonth">Начало кредита</StyledLabel>
            <MonthInput
              onChange={this.onStartMonthChange}
              minYear={today.getFullYear() - 30}
              maxYear={today.getFullYear() + 5}
              value={{ num: this.props.calcReq.startMonth, year: this.props.calcReq.startYear }}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col xs={12}>
            <StyledLabel htmlFor="needCommissions">
              <HelpContainer text="Учитывать ли разовую и ежегодную комиссию">Учесть комиссии</HelpContainer>
            </StyledLabel>
            <Switch onChange={this.onNeedCommissionsChange} checked={this.props.calcReq.needCommissions} />
          </Col>
        </FormRow>
        {this.renderCommissionForm()}
        {this.renderRentCompareForm()}
      </div>
    )
  }

  private renderInflationForm() {
    if (!this.props.needInflation) {
      return null
    }

    return (
      <FormRow>
        <Col xs={12}>
          <StyledLabel htmlFor="yearlyInflationPercent">
            <HelpContainer text="Расчет будет корректным только при условии, что зарплата растет не медленнее инфляции">
              Среднегодовая инфляция
            </HelpContainer>
          </StyledLabel>
          <PercentInput
            name="yearlyInflationPercent"
            value={this.props.calcReq.yearlyInflationPercent}
            onInvalidInput={this.props.onInvalidInput}
            onValueChange={this.onChangeYearlyInflationPercent}
            onStartCalculating={this.props.onStartCalculating}
          />
        </Col>
      </FormRow>
    )
  }

  render() {
    return (
      <Box as="form" onSubmit={this.onSubmit}>
        <CurrencyInputRow
          name="price"
          label="Стоимость недвижимости"
          value={this.props.calcReq.price}
          onInvalidInput={this.props.onInvalidInput}
          onValueChange={this.onChangePrice}
          onStartCalculating={this.props.onStartCalculating}
        />
        <CurrencyInputRow
          name="downPayment"
          label="Первоначальный взнос"
          value={this.props.calcReq.downPayment}
          onInvalidInput={this.props.onInvalidInput}
          onValueChange={this.onChangeDownPayment}
          onStartCalculating={this.props.onStartCalculating}
        />
        <PercentInputRow
          name="interestRate"
          label="Процентная ставка"
          value={this.props.calcReq.interestRate}
          onInvalidInput={this.props.onInvalidInput}
          onValueChange={this.onChangeInterestRate}
          onStartCalculating={this.props.onStartCalculating}
        />

        <TermInputRow
          name="termMonths"
          label="Срок ипотеки"
          initialTerm={this.initialTerm}
          onTermChange={this.onTermChange}
        />

        {this.renderInflationForm()}
        <ShowMoreParamsButton
          showMoreParams={this.state.showMoreParams}
          onChangeShowMoreParams={this.onChangeShowMoreParams}
        />
        {this.renderMoreParamsForm()}
      </Box>
    )
  }
}

export default CalculatorForm
