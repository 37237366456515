/** @jsx jsx */
import React from "react"
import { jsx, Input, Flex, Text } from "theme-ui"
import MaskedInput from "react-text-mask"
import createNumberMask from "text-mask-addons/dist/createNumberMask"
import { debounce } from "lodash"

import ErrorBox from "./error"

type VoidFn = () => void

type Props = {
  name: string
  value: number
  onValueChange: (newValue: number) => void
  onInvalidInput: () => void
  onStartCalculating: VoidFn | null
}

type State = {
  validationError: string | null
  valueStr: string
}

const thousandsSeparatorSymbol = ` `

const defaultMaskOptions = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol,
  allowDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: 9, // limit length of integer numbers
}

const defaultMask = createNumberMask(defaultMaskOptions)

const appendixSx = { zIndex: -1 }

export default class CurrencyInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      validationError: null,
      valueStr: props.value.toString(),
    }
  }

  private liftUpChange = (newValue: string) => {
    const re = new RegExp(thousandsSeparatorSymbol, `g`)
    const num = parseInt(newValue.replace(re, ``), 10)
    this.props.onValueChange(num)
  }
  private liftUpChangeDebounced = debounce(this.liftUpChange, 300)

  private liftUpInvalidInput = () => this.props.onInvalidInput()

  private onChange = (e: any) => {
    const newValue: string = e.target.value
    if (newValue.length === 0) {
      const validationError = `Пожалуйста, введите число`
      this.setState({ validationError, valueStr: newValue })
      this.liftUpInvalidInput()
      return
    }
    const wasValidationError = this.state.validationError
    this.setState({ validationError: null, valueStr: newValue })
    if (wasValidationError || !this.props.onStartCalculating) {
      this.liftUpChange(newValue)
    } else {
      this.props.onStartCalculating() // to show loader
      this.liftUpChangeDebounced(newValue)
    }
  }

  private renderInput = (ref, props) => {
    return (
      <Input
        variant={this.state.validationError ? `invalidInlineInput` : `inlineInput`}
        sx={{ flex: `1`, zIndex: 0 }}
        ref={ref}
        {...props}
      />
    )
  }

  render() {
    return (
      <div>
        <Flex>
          <MaskedInput
            mask={defaultMask}
            name={this.props.name}
            inputMode="numeric"
            type="text"
            value={this.state.valueStr}
            onChange={this.onChange}
            render={this.renderInput}
          />
          <Text as="span" variant="inlineInputAppend" sx={appendixSx}>
            руб
          </Text>
        </Flex>
        {this.state.validationError && (
          <Flex>
            <ErrorBox>{this.state.validationError}</ErrorBox>
          </Flex>
        )}
      </div>
    )
  }
}
