import { CalcRequest } from "./calc-request"

export type PaymentStatus = {
  commissionType?: string
  debtPayment?: number
  percentPayment?: number
  totalPayment: number
  debt: number
  month: number
  year: number
  paymentNumber: number
  isPrepayment?: boolean
}

type OverPayment = {
  total: number
  percent: number
  totalWithInflation: number
  percentWithInflation: number
}

type MonthlyPayment = {
  total: number
  totalAmortized: number
  from: number
  amortizedFrom: number
  to: number
  amortizedTo: number
}

export type RentComparisonItem = {
  rentPayment: number
  housePrice: number
  accumulationFailed: boolean
  accumulationWon: boolean
  totalAccumulated: number
  debtPaid: number
}

type RentComparison = {
  avgMonthlyHouseServicePayment: number
  items: RentComparisonItem[]
  rentIsBetterAfterMonths: number
}

type PrepaymentsResult = {
  termReducedByMonths: number | null
  monthlyPaymentReducedTo: number | null
  originalAnnuityMonthlyPayment: number
  originalOverPayment: OverPayment
}

export type CalcResult = {
  paymentStatuses: PaymentStatus[]
  overPayment: OverPayment
  monthlyPayment: MonthlyPayment
  rentComparison?: RentComparison
  prepaymentsResult?: PrepaymentsResult
}

export const validateCalcReq = (req: CalcRequest): string | null => {
  if (req.price <= req.downPayment) {
    return `Первоначальный взнос не может быть больше цены недвижимости`
  }
  return null
}

export default class Calculator {
  calculate(req: CalcRequest): CalcResult {
    const startedAt = performance.now()
    const res = this.calculatePrimary(req)
    if (req.needCompareWithRent) {
      this.compareWithRent(req, res)
    }
    console.info(`Calculated in ${performance.now() - startedAt}ms`, res)
    return res
  }

  private calculatePrimary(req: CalcRequest): CalcResult {
    const initialDebt = req.price - req.downPayment
    let curDebt = initialDebt // TODO: validate >= 0
    let curMonth = req.startMonth
    let curYear = req.startYear

    let annuityMonthlyPayment = null
    let differentialMonthlyDeptPart = null
    const monthlyPercent = req.interestRate / 100 / 12
    const termMonths = req.termMonths
    let monthsPaid = 0

    const calcTermMonthsLeft = (startPaymentMonth: number): number => {
      // Don't just use `termMonths - monthsPaid` instead of such calculation because it won't work with mixed prepayment types.
      let termMonthsLeft = 0
      let tempCurDebt = curDebt
      for (let i = startPaymentMonth; i < termMonths && tempCurDebt >= 1e-6 /* rounding errors */; i++) {
        const percentPart = tempCurDebt * monthlyPercent
        const debtPart = req.isAnnuity ? annuityMonthlyPayment - percentPart : differentialMonthlyDeptPart
        tempCurDebt -= debtPart
        termMonthsLeft++
      }
      return termMonthsLeft
    }

    const recalcMonthlyPayment = (termMonthsLeft: number) => {
      if (req.isAnnuity) {
        const monthlyPercentOverTerm = Math.pow(1 + monthlyPercent, termMonthsLeft)
        annuityMonthlyPayment =
          monthlyPercent === 0
            ? curDebt / termMonthsLeft
            : (curDebt * monthlyPercent * monthlyPercentOverTerm) / (monthlyPercentOverTerm - 1)
      } else {
        differentialMonthlyDeptPart = curDebt / termMonthsLeft
      }
    }
    recalcMonthlyPayment(termMonths)
    const originalAnnuityMonthlyPayment = annuityMonthlyPayment

    const onetimeCommission = req.onetimeComissionPercent / 100
    const yearlyCommission = req.yearlyComissionPercent / 100

    const paymentStatuses: PaymentStatus[] = []
    if (req.needCommissions) {
      paymentStatuses.push({
        commissionType: "onetime",
        totalPayment: onetimeCommission * curDebt,
        debt: curDebt,
        month: curMonth,
        year: curYear,
        paymentNumber: 0,
      })
    }

    for (let i = 0; i < termMonths; i++) {
      if (curDebt < 1e-6 /* rounding errors */) {
        console.info("reached zero debt before end of credit term: i = %d", i)
        break
      }

      for (const p of req.prepayments) {
        if (curMonth === p.month && curYear === p.year) {
          const termMonthsLeft = calcTermMonthsLeft(i)
          const prepaymentValue = Math.min(curDebt, p.value)
          curDebt -= prepaymentValue
          paymentStatuses.push({
            debtPayment: prepaymentValue,
            percentPayment: 0,
            totalPayment: prepaymentValue,
            debt: curDebt,
            month: curMonth,
            year: curYear,
            paymentNumber: i,
            isPrepayment: true,
          })
          if (!p.isPayOffEarlierType) {
            recalcMonthlyPayment(termMonthsLeft)
          }
          // no break because multiple prepayments may occur in one month
        }
      }

      const percentPart = curDebt * monthlyPercent
      const debtPart = Math.min(
        curDebt,
        req.isAnnuity ? annuityMonthlyPayment - percentPart : differentialMonthlyDeptPart
      )
      const totalPayment = req.isAnnuity ? annuityMonthlyPayment : debtPart + percentPart
      curDebt -= debtPart

      paymentStatuses.push({
        debtPayment: debtPart,
        percentPayment: percentPart,
        totalPayment,
        debt: Math.max(curDebt, 0),
        month: curMonth,
        year: curYear,
        paymentNumber: i,
      })
      ++monthsPaid

      if (++curMonth > 12) {
        curMonth = 1
        curYear++
      }

      if ((i + 1) % 12 === 0 && curDebt > 0 && req.needCommissions) {
        paymentStatuses.push({
          commissionType: "yearly",
          totalPayment: yearlyCommission * curDebt,
          debt: curDebt,
          month: curMonth,
          year: curYear,
          paymentNumber: i,
        })
      }
    }

    let overPaymentWithInflation = -initialDebt
    if (req.needInflation) {
      let currentInflationCoef = 1.0
      const inflationCoefMultiplicator = (100 - req.yearlyInflationPercent) / 100.0
      for (const ps of paymentStatuses) {
        if ((ps.paymentNumber + 1) % 12 === 0) {
          currentInflationCoef *= inflationCoefMultiplicator
        }
        overPaymentWithInflation += ps.totalPayment * currentInflationCoef
      }
    }

    let overPaymentTotal = -initialDebt
    for (const ps of paymentStatuses) {
      overPaymentTotal += ps.totalPayment
    }

    const overPayment: OverPayment = {
      total: overPaymentTotal,
      percent: (100 * overPaymentTotal) / initialDebt,
      totalWithInflation: overPaymentWithInflation,
      percentWithInflation: (100 * overPaymentWithInflation) / initialDebt,
    }

    let amortizationMonthlyAdd = 0
    for (const ps of paymentStatuses) {
      if (ps.commissionType) {
        amortizationMonthlyAdd += ps.totalPayment
      }
    }
    amortizationMonthlyAdd /= termMonths
    const monthlyPayment: MonthlyPayment = {
      total: 0,
      totalAmortized: 0,
      from: 0,
      amortizedFrom: 0,
      to: 0,
      amortizedTo: 0,
    }
    const firstPaymentValue = paymentStatuses.find(ps => !ps.commissionType && !ps.isPrepayment).totalPayment
    let lastPaymentValue = null
    for (let i = paymentStatuses.length - 1; i >= 0; i--) {
      if (!paymentStatuses[i].commissionType) {
        lastPaymentValue = paymentStatuses[i].totalPayment
        break
      }
    }

    if (req.isAnnuity) {
      monthlyPayment.total = firstPaymentValue
      monthlyPayment.totalAmortized = monthlyPayment.total + amortizationMonthlyAdd
    } else {
      monthlyPayment.from = firstPaymentValue
      monthlyPayment.amortizedFrom = monthlyPayment.from + amortizationMonthlyAdd

      monthlyPayment.to = lastPaymentValue
      monthlyPayment.amortizedTo = monthlyPayment.to + amortizationMonthlyAdd
    }

    // Recalculates everything, maybe makes sense to optimize, but currently calculator works under 1ms.
    const calcOriginalOverPayment = (): OverPayment => {
      const reqWoPrepayments = { ...req }
      reqWoPrepayments.prepayments = []
      const origRes = this.calculatePrimary(reqWoPrepayments)
      return origRes.overPayment
    }

    // TODO: support for differential payments
    const prepaymentsResult =
      req.prepayments && req.prepayments.length && req.isAnnuity
        ? {
            termReducedByMonths: req.prepayments.some(p => p.isPayOffEarlierType) ? termMonths - monthsPaid : null,
            monthlyPaymentReducedTo: req.prepayments.some(p => !p.isPayOffEarlierType) ? lastPaymentValue : null,
            originalAnnuityMonthlyPayment,
            originalOverPayment: calcOriginalOverPayment(),
          }
        : null

    return {
      paymentStatuses,
      overPayment,
      monthlyPayment,
      prepaymentsResult,
    }
  }

  private compareWithRent(req: CalcRequest, res: CalcResult): void {
    const payments = res.paymentStatuses
    let totalAccumulatedRub = req.downPayment
    let currentRentPayment = req.monthlyRentPayment
    let housePriceRub = req.price
    const rentIncreaseCoef = (100 + req.yearlyRentIncreasePercent) / 100
    const homePriceIncreaseCoef = (100 + req.yearlyHomePriceIncreasePercent) / 100
    const monthlyAmmortizedRepairsPayment = req.repairsTotal / req.termMonths
    const monthlyHouseServicePayment = monthlyAmmortizedRepairsPayment + req.monthlyServicesPayment

    const items: RentComparisonItem[] = []

    let accumulatedInThisYear = 0
    let debtPaid = req.downPayment
    for (let i = 0; i < payments.length; i++) {
      if ((i + 1) % 12 === 0) {
        totalAccumulatedRub *= (100 + req.bankRate) / 100
        currentRentPayment *= rentIncreaseCoef
        housePriceRub *= homePriceIncreaseCoef
        if (req.useIIS) {
          totalAccumulatedRub += Math.min(accumulatedInThisYear, 400000) * 0.13
          accumulatedInThisYear = 0
        }
      }

      const comparisonItem: RentComparisonItem = {
        rentPayment: currentRentPayment,
        housePrice: housePriceRub,
        accumulationFailed: false,
        accumulationWon: false,
        totalAccumulated: 0,
        debtPaid: 0,
      }

      let paymentsDiff = payments[i].totalPayment
      if (!payments[i].commissionType) {
        paymentsDiff -= currentRentPayment
        paymentsDiff += monthlyHouseServicePayment
      }
      if (paymentsDiff > 0) {
        totalAccumulatedRub += paymentsDiff
        accumulatedInThisYear += paymentsDiff
      } else {
        comparisonItem.accumulationFailed = true
      }

      comparisonItem.totalAccumulated = totalAccumulatedRub
      comparisonItem.accumulationWon = totalAccumulatedRub >= housePriceRub

      debtPaid += res.paymentStatuses[i].debtPayment || 0
      comparisonItem.debtPaid = debtPaid

      items.push(comparisonItem)
    }

    const firstWonIndex = items.findIndex(item => item.accumulationWon)
    const toMonthCount = (ps: PaymentStatus) => ps.year * 12 + ps.month
    const rentIsBetterAfterMonths =
      firstWonIndex > 0 ? toMonthCount(res.paymentStatuses[firstWonIndex]) - toMonthCount(res.paymentStatuses[0]) : -1

    res.rentComparison = {
      avgMonthlyHouseServicePayment: monthlyHouseServicePayment,
      items,
      rentIsBetterAfterMonths,
    }
  }
}
