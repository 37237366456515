import React, { useCallback } from "react"

import RadioButton from "./radio-button"

type Option = {
  value: string
  label: string
}

type Props = {
  options: Option[]
  activeValue: string
  name: string
  onChange: (selected: string) => void
}

const RadioGroup = React.memo((props: Props) => (
  <>
    {props.options.map((opt, i) => (
      <RadioButton
        key={i}
        name={props.name}
        {...opt}
        isActive={props.activeValue === opt.value}
        onChange={useCallback(() => props.onChange(opt.value), [props.onChange])}
      />
    ))}
  </>
))

export default RadioGroup
