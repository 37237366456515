/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

import StateLink, { LocationState } from "components/state-link"

type Props = {
  locationState: LocationState
}

const RelatedContent = React.memo(({ locationState }: Props) => {
  const related = [
    ["Ипотека или аренда - калькулятор сравнения что выгоднее", "/ипотека-или-накопление-и-аренда-что-выгоднее/"],
    ["Калькулятор ипотеки с досрочным погашением", "/ипотечный-калькулятор-с-досрочным-погашением/"],
    ["Ипотечный калькулятор", "/"],
    ["Ипотечный калькулятор с учетом инфляции", "/калькулятор-ипотеки-с-учетом-инфляции/"],
    ["Формула расчета ипотеки", "/формула-расчета-ипотеки/"],
  ]

  return (
    <div sx={{ mt: 3 }}>
      <p sx={{ fontFamily: `heading`, fontSize: 4 }}>Полезное</p>
      <ul>
        {related.map((rel, i) => (
          <li key={i}>
            <StateLink state={locationState} to={rel[1]}>
              {rel[0]}
            </StateLink>
          </li>
        ))}
      </ul>
    </div>
  )
})

export default RelatedContent
