export const formatCurrency = (v: number, needCurrencyUnit = true): string => {
  const vStr = Math.round(v).toString()
  const groups: string[] = []
  const groupSize = 3
  let i = vStr.length % groupSize
  if (i !== 0) {
    groups.push(vStr.substr(0, vStr.length % groupSize))
  }
  for (; i < vStr.length; i += groupSize) {
    groups.push(vStr.substr(i, groupSize))
  }

  return groups.join(" ") + (needCurrencyUnit ? ` руб.` : ``)
}

export const formatMonth = (month: number, year: number): string => {
  return `${month < 10 ? "0" : ""}${month}.${year}`
}
