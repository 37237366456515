/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Row, Col } from "react-flexbox-grid"
import Select from "react-select"

import { selectNoOptionsText, selectScreenReaderStatus } from "utils/lang"
import { isSSR } from "utils/ssr"

export type Month = {
  num: number
  year: number
}

type Props = {
  minYear: number
  maxYear: number
  value: Month
  onChange: (m: Month) => void
}

type Option = {
  value: number
  label: string
}

type State = {
  month: Month
  monthOpt: Option
  yearOpt: Option
}

const range = (start: number, end: number): number[] => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx)
}

const yearToOption = (year: number): Option => ({
  value: year,
  label: `${year}`,
})

const monthNames = [
  `январь`,
  `февраль`,
  `март`,
  `апрель`,
  `май`,
  `июнь`,
  `июль`,
  `август`,
  `сентябрь`,
  `октябрь`,
  `ноябрь`,
  `декабрь`,
]
const monthToOption = (month: number): Option => ({
  value: month,
  label: monthNames[month - 1],
})

export default class MonthInput extends React.PureComponent<Props, State> {
  private yearOptions: Option[]
  private monthOptions: Option[]

  constructor(props: Props) {
    super(props)

    this.yearOptions = range(props.minYear, props.maxYear).map(yearToOption)
    this.monthOptions = range(1, 12).map(monthToOption)

    this.state = {
      month: this.props.value,
      monthOpt: this.monthOptions.find(opt => opt.value === this.props.value.num),
      yearOpt: this.yearOptions.find(opt => opt.value === this.props.value.year),
    }
  }

  private onChangeMonthNumber = (v: Option) => {
    const month = { ...this.state.month, num: v.value }
    this.setState({ month, monthOpt: v })
    this.props.onChange(month)
  }

  private onChangeYear = (v: Option) => {
    const month = { ...this.state.month, year: v.value }
    this.setState({ month, yearOpt: v })
    this.props.onChange(month)
  }

  render() {
    const menuPortalTarget = isSSR() ? undefined : window.document.body
    return (
      <Row>
        <Col xs={6}>
          <Select
            value={this.state.monthOpt}
            options={this.monthOptions}
            onChange={this.onChangeMonthNumber}
            menuPortalTarget={menuPortalTarget}
            noOptionsMessage={selectNoOptionsText}
            screenReaderStatus={selectScreenReaderStatus}
          />
        </Col>
        <Col xs={6}>
          <Select
            value={this.state.yearOpt}
            options={this.yearOptions}
            onChange={this.onChangeYear}
            menuPortalTarget={menuPortalTarget}
            noOptionsMessage={selectNoOptionsText}
            screenReaderStatus={selectScreenReaderStatus}
          />
        </Col>
      </Row>
    )
  }
}
