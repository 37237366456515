import today from "./today"

export type Prepayment = {
  month: number
  year: number
  value: number
  isPayOffEarlierType: boolean
  id: number // to distinguish between prepayments while there is no validation of the same year+month
}

// Don't rename fields because they are used in shared url params.
// Be aware when adding/deleting fields.
export type CalcRequest = {
  price: number
  downPayment: number
  interestRate: number
  termMonths: number
  isAnnuity: boolean
  onetimeComissionPercent: number
  yearlyComissionPercent: number
  yearlyInflationPercent: number
  startMonth: number
  startYear: number

  needCommissions: boolean
  needInflation: boolean

  needCompareWithRent: boolean
  monthlyRentPayment: number
  yearlyRentIncreasePercent: number
  yearlyHomePriceIncreasePercent: number
  bankRate: number
  monthlyServicesPayment: number
  repairsTotal: number
  useIIS: boolean

  prepayments: Prepayment[]
}

export const defaultCalcReq: CalcRequest = {
  price: 6000000,
  downPayment: 1000000,
  interestRate: 9.4,
  termMonths: 20 * 12,
  isAnnuity: true,
  onetimeComissionPercent: 1.5,
  yearlyComissionPercent: 1.5,
  yearlyInflationPercent: 5,
  startMonth: today.getMonth() + 1,
  startYear: today.getFullYear(),

  needCommissions: false,
  needInflation: false,

  needCompareWithRent: false,
  monthlyRentPayment: 25000,
  yearlyRentIncreasePercent: 3,
  yearlyHomePriceIncreasePercent: 3,
  bankRate: 6,
  monthlyServicesPayment: 5000,
  repairsTotal: 1000000,
  useIIS: false,

  prepayments: [],
}
