import React from "react"

interface IProps {
  name: string
}

interface IState {
  hasError: boolean
}

export default class ErrorBoundary extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error boundary catched error:", error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <h1>Не удалось загрузить {this.props.name}.</h1>
    }

    return this.props.children
  }
}
