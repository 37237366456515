import React, { memo } from "react"
import styled from "utils/styled"

type Props = {
  isActive: boolean
  name: string
  value: string
  label: string
  onChange: () => void
}

const Container = styled.label`
  display: inline-block;
  white-space: nowrap;
  margin-right: ${p => p.theme.space[5]};
`

const LabelSpan = memo(styled.span`
  margin-left: ${p => p.theme.space[2]};
  vertical-align: middle;
`)

const Input = memo(styled.input`
  vertical-align: middle;
`)

const RadioButton = (props: Props) => (
  <Container>
    <Input type="radio" name={props.name} value={props.value} checked={props.isActive} onChange={props.onChange} />
    <LabelSpan>{props.label}</LabelSpan>
  </Container>
)

export default memo(styled(RadioButton)`
  margin-top: ${p => p.theme.space[2]};
`)
