/** @jsx jsx */
import React from "react"
import { jsx, Input, Flex, Text } from "theme-ui"
import { debounce } from "lodash"

import ErrorBox from "./error"

type Props = {
  name: string
  value: number

  onValueChange: (newValue: number) => void
  onInvalidInput: () => void
  onStartCalculating: () => void
}

type State = {
  validationError: string | null
  valueStr: string
}

const percentRe = /^(100(\.0+)?|([1-9]\d|\d)(\.\d+)?)$/

export default class PercentInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      validationError: null,
      valueStr: props.value.toString(),
    }
  }

  private isValidPercentString(s: string): boolean {
    return s.match(percentRe) ? true : false
  }

  private liftUpChange = (normalizedValue: string) => {
    this.props.onValueChange(parseFloat(normalizedValue))
  }
  private liftUpChangeDebounced = debounce(this.liftUpChange, 300)

  private liftUpInvalidInput = () => this.props.onInvalidInput()

  private onChange = (e: any) => {
    const newValue: string = e.target.value
    const normalizedValue = newValue.replace(/,/g, `.`).replace(/[^\d.]/g, ``)
    let validationError = null
    if (normalizedValue.length === 0) {
      validationError = `Пожалуйста, введите число`
    } else if (!this.isValidPercentString(normalizedValue)) {
      validationError = `Пожалуйста, введите процент`
    }
    if (validationError !== null) {
      this.setState({ validationError, valueStr: normalizedValue })
      this.liftUpInvalidInput()
      return
    }

    const wasValidationError = this.state.validationError
    this.setState({ validationError: null, valueStr: normalizedValue })

    if (wasValidationError) {
      this.liftUpChange(newValue)
    } else {
      this.props.onStartCalculating() // to show loader
      this.liftUpChangeDebounced(newValue)
    }
  }

  render() {
    return (
      <div>
        <Flex>
          <Input
            variant={this.state.validationError ? `invalidInlineInput` : `inlineInput`}
            sx={{ flex: `1`, zIndex: 0 }}
            inputMode="decimal"
            type="text"
            name={this.props.name}
            value={this.state.valueStr}
            onChange={this.onChange}
          />
          <Text as="span" variant="inlineInputAppend" sx={{ zIndex: -1 }}>
            %
          </Text>
        </Flex>
        {this.state.validationError && (
          <Flex>
            <ErrorBox>{this.state.validationError}</ErrorBox>
          </Flex>
        )}
      </div>
    )
  }
}
