import React, { memo } from "react"
import { FaQuestionCircle } from "react-icons/fa"
import Tooltip from "rc-tooltip"

import styled from "utils/styled"

type Props = {
  text: string
  children: any
}

const IconContainer = memo(styled.span`
  svg {
    display: inline-block;
    overflow: visible;
    vertical-align: -0.125em;
  }
  margin-left: ${p => p.theme.space[1]};
`)

const HelpContainer = React.memo((props: Props) => (
  <>
    {props.children}
    <Tooltip placement="top" trigger={["hover"]} overlay={<span>{props.text}</span>}>
      <IconContainer>
        <FaQuestionCircle />
      </IconContainer>
    </Tooltip>
  </>
))

export default HelpContainer
