/** @jsx jsx */
import React, { useCallback, memo } from "react"
import { jsx, Box, Button, Label, Flex } from "theme-ui"
import Modal from "react-modal"
import { Row, Col } from "react-flexbox-grid"

import { Prepayment } from "./calc-request"
import CurrencyInput from "./currency-input"
import MonthInput from "./month-input"
import RadioGroup from "./radio-group"
import today, { todayMonth } from "./today"
import HelpContainer from "./help"
import styled from "utils/styled"
import theme from "utils/theme"
import { formatCurrency, formatMonth } from "./format"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: theme.space[5],
    transform: "translate(-50%, -50%)",
  },
}

const FormRow = styled(Row)`
  margin-bottom: ${p => p.theme.space[3]};
`

const StyledLabel = memo(styled(Label)`
  margin-bottom: ${p => p.theme.space[1]};
`)

const PrepaymentItem = memo(styled.div`
  background: ${p => p.theme.colors.success};
  color: white;
  box-shadow: inset 0 0 2px ${p => p.theme.colors.success}, 0 0 2px ${p => p.theme.colors.success};
  border-radius: ${p => p.theme.radii[2]};
  padding: ${p => p.theme.space[2]};
  margin-bottom: ${p => p.theme.space[2]};
`)

type Props = {
  onChange: (prepayments: Prepayment[]) => void
  onStartCalculating: () => void
  prepayments: Prepayment[]
}

const prepaymentTypeOptions = [
  { value: `pay_off_earlier`, label: `Уменьшить срок кредита` },
  { value: `reduce_monthly_payment`, label: `Уменьшить месячный платеж` },
]

const PrepaymentsController = React.memo((props: Props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen])
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen])

  const [isValid, setIsValid] = React.useState(true)
  const onInvalidInput = useCallback(() => setIsValid(false), [setIsValid])

  const [curPrice, setCurPrice] = React.useState(300000)
  const onPriceChange = React.useCallback(
    (v: number) => {
      setCurPrice(v)
      setIsValid(true)
    },
    [setCurPrice, setIsValid]
  )

  const [curMonth, setCurMonth] = React.useState(todayMonth)
  const [prepaymentType, setPrepaymentType] = React.useState(`pay_off_earlier`)

  const [prepayments, setPrepayments] = React.useState(props.prepayments)
  const [lastPrepaymentId, setLastPrepaymentId] = React.useState(0)
  const addNewPrepayment = useCallback(() => {
    const newPrepayments = prepayments
      .concat([
        {
          id: lastPrepaymentId + 1,
          value: curPrice,
          month: curMonth.num,
          year: curMonth.year,
          isPayOffEarlierType: prepaymentType === `pay_off_earlier`,
        },
      ])
      .sort((a, b) => {
        if (a.year !== b.year) {
          return a.year - b.year
        }
        return a.month - b.month
      })
    setPrepayments(newPrepayments)
    setIsOpen(false)
    setLastPrepaymentId(lastPrepaymentId + 1)
    props.onChange(newPrepayments)
  }, [
    prepayments,
    setPrepayments,
    curPrice,
    curMonth,
    props.onChange,
    setIsOpen,
    lastPrepaymentId,
    setLastPrepaymentId,
    prepaymentType,
  ])

  const removePrepaymentById = (id: number) => {
    const newPrepayments = prepayments.filter(p => p.id !== id)
    setPrepayments(newPrepayments)
    props.onChange(newPrepayments)
  }

  return (
    <div>
      {prepayments.map((p, i) => (
        <PrepaymentItem key={i}>
          <Row>
            <Col xs={8}>
              Досрочный платеж от {formatMonth(p.month, p.year)}: {formatCurrency(p.value)} (сокращен{" "}
              {p.isPayOffEarlierType ? `срок` : `месячный платеж`})
            </Col>
            <Col xs={4}>
              <Flex sx={{ justifyContent: `flex-end` }}>
                <Button variant="secondary" onClick={() => removePrepaymentById(p.id)}>
                  Удалить
                </Button>
              </Flex>
            </Col>
          </Row>
        </PrepaymentItem>
      ))}
      <div sx={{ mb: 3 }}>
        <Button onClick={openModal}>Добавить досрочный платеж</Button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Окно создания досрочного платежа"
      >
        <Box as="form" onSubmit={useCallback(e => e.preventDefault(), [])}>
          <h3>Новый досрочный платеж</h3>
          <FormRow>
            <Col xs={12}>
              <StyledLabel>Дата платежа</StyledLabel>
              <MonthInput
                minYear={today.getFullYear() - 20}
                maxYear={today.getFullYear() + 20}
                value={curMonth}
                onChange={setCurMonth}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col xs={12}>
              <StyledLabel htmlFor="prepaymentPrice">Размер платежа</StyledLabel>
              <CurrencyInput
                name="prepaymentPrice"
                value={curPrice}
                onValueChange={onPriceChange}
                onInvalidInput={onInvalidInput}
                onStartCalculating={null}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col xs={12}>
              <StyledLabel htmlFor="creditType">
                <HelpContainer text="Уменьшение срока кредита минимизирует общую переплату за кредит, но месячный платеж не меняется. Уменьшение месячного платежа - наоборот.">
                  Тип пересчета
                </HelpContainer>
              </StyledLabel>
              <RadioGroup
                options={prepaymentTypeOptions}
                activeValue={prepaymentType}
                name="prepaymentType"
                onChange={setPrepaymentType}
              />
            </Col>
          </FormRow>
          {isValid && (
            <FormRow>
              <Button onClick={addNewPrepayment} sx={{ mt: 3 }}>
                Добавить
              </Button>
            </FormRow>
          )}
        </Box>
      </Modal>
    </div>
  )
})

export default PrepaymentsController
