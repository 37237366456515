/** @jsx jsx */
import React, { memo, Suspense } from "react"
import { WindowLocation } from "@reach/router"
import { replace } from "gatsby"
import { jsx, Button } from "theme-ui"
import { Row, Col } from "react-flexbox-grid"
import { default as ReactTooltip } from "rc-tooltip"
import queryString from "query-string"
import "rc-tooltip/assets/bootstrap.css"
import Loader from "components/loader"
import { FaCopy, FaExclamation } from "react-icons/fa"

import styled from "utils/styled"

import Calculator, { CalcResult, validateCalcReq, PaymentStatus, RentComparisonItem } from "./calculator"
import { CalcRequest, defaultCalcReq, Prepayment } from "./calc-request"
import ErrorBox from "./error"
import PrepaymentsController from "./prepayments-controller"
import { ResponsiveTableContainer } from "components/table"
import CalculatorForm from "./form"
import HelpContainer from "./help"
import { formatCurrency, formatMonth } from "./format"
import { pluralize } from "utils/lang"
import { isSSR } from "utils/ssr"
import ErrorBoundary from "utils/error-boundary"
import { trackGoal } from "utils/analytics"

const Chart = React.lazy(() => import(/* webpackPrefetch: true */ "./chart"))

const FormColumn = styled(Col)`
  border-right: 1px solid ${p => p.theme.colors.input.border};
  padding-right: 1em;
`

const Td = styled.td`
  white-space: nowrap;
  border: 1px solid ${p => p.theme.colors.ui.border};
  text-align: center;
  vertical-align: middle;
  padding: ${p => p.theme.space[1]};
`

const Th = styled.th`
  border: 1px solid ${p => p.theme.colors.ui.border};
  text-align: center;
  vertical-align: middle;
  padding: ${p => p.theme.space[1]};
  font-size: ${p => p.theme.fontSizes[1]};
`

const IconedLink = memo(styled.a`
  margin-right: ${p => p.theme.space[2]};
  display: inline;
  margin-bottom: 0;
  margin-left: ${p => p.theme.space[3]};
  &:hover {
    color: ${p => p.theme.colors.primaryLightAnalogous};
  }

  svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
    margin-right: ${p => p.theme.space[1]};
  }
`)

type Props = {
  needPrepayments?: boolean
  needInflation?: boolean
  needCompareWithRent?: boolean
  location: WindowLocation
}

type Validation = {
  totalError: string | null
  inputsError: string | null
}

type ViewSettings = {
  showTable: boolean
  isFullTable: boolean
}

type State = {
  validation: Validation
  calcReq: CalcRequest
  calcRes: CalcResult
  viewSettings: ViewSettings

  // Don't perform expensive rendering and calculations until user has interacted with page.
  hadInteraction: boolean

  // Track only one goal in yandex.metrika.
  didTrackGoal: boolean

  // With "?" prefix if not empty
  currentSerializedRequest: string

  isCalculating: boolean
}

const formRowSx = { mb: 2 }

const buildCalcReq = (props: Props): CalcRequest => {
  if (isSSR()) {
    return defaultCalcReq
  }

  const params = queryString.parse(props.location.search, {
    parseNumbers: true,
    parseBooleans: true,
    arrayFormat: `bracket`,
  })
  if (params.sum === undefined) {
    // Parse the new format.
    return {
      ...defaultCalcReq,
      ...params,
      prepayments:
        params.prepayments && props.needPrepayments
          ? (params.prepayments as string[]).map(s => JSON.parse(s) as Prepayment)
          : [],
    }
  }

  // Parse the old format.
  const calcReq = { ...defaultCalcReq }
  if (params.sum !== undefined) {
    calcReq.price = parseInt(params.sum as string, 10)
    calcReq.downPayment = 0
  }
  if (params.percent !== undefined) {
    calcReq.interestRate = parseFloat(params.percent as string)
  }
  if (params.years !== undefined) {
    calcReq.termMonths = parseInt(params.years as string, 10) * 12
  }
  if (params.pt === "d") {
    calcReq.isAnnuity = false
  }

  return calcReq
}

export default class CalculatorComponent extends React.Component<Props, State> {
  private calculator: Calculator

  constructor(props: Props) {
    super(props)

    const calcReq = buildCalcReq(props)
    calcReq.needInflation = this.props.needInflation ? true : false
    calcReq.needCompareWithRent = this.props.needCompareWithRent ? true : false

    this.calculator = new Calculator()
    const calcRes = this.calculator.calculate(calcReq)
    this.state = {
      validation: {
        totalError: null,
        inputsError: null,
      },
      calcReq,
      calcRes,
      viewSettings: {
        showTable: this.props.needCompareWithRent ? true : false,
        isFullTable: false,
      },
      hadInteraction: false,
      didTrackGoal: false,
      currentSerializedRequest: props.location.search,
      isCalculating: false,
    }

    this.onTableViewClick = this.onTableViewClick.bind(this)
    this.onChangeIsFullTable = this.onChangeIsFullTable.bind(this)
    this.copyLink = this.copyLink.bind(this)
    this.onInvalidInput = this.onInvalidInput.bind(this)
  }

  shouldComponentUpdate(newProps: Props, newState: State): boolean {
    if (newState !== this.state) {
      console.info(`shouldComponentUpdate: component state changed`, this.state, newState)
      return true
    }

    if (
      this.props.needCompareWithRent !== newProps.needCompareWithRent ||
      this.props.needInflation !== newProps.needInflation ||
      this.props.needPrepayments !== newProps.needPrepayments
    ) {
      console.info(`shouldComponentUpdate: needs differ`, this.props, newProps)
      return true
    }

    if (this.state.currentSerializedRequest !== newProps.location.search) {
      console.info(
        `shouldComponentUpdate: serialized requests`,
        this.state.currentSerializedRequest,
        newProps.location.search
      )
      return true
    }

    console.info(`shouldComponentUpdate: no need to update`, this.state, this.props, newProps)
    return false
  }

  componentDidUpdate(prevProps, prevState) {
    console.info(`component did update, props:`, this.props, prevProps)
    console.info(`component did update, state:`, this.state, prevState)
  }

  private onInvalidInput() {
    this.setState({
      validation: {
        ...this.state.validation,
        inputsError: `Пожалуйста, скорректируйте поля формы для расчета`,
      },
    })
  }

  private onStartCalculating = () => this.setState({ isCalculating: true })

  private serializeCalcReq(calcReq: CalcRequest): string {
    const params = {
      ...calcReq,
      prepayments: calcReq.prepayments.map(p => JSON.stringify(p)),
    }
    const filteredParams = Object.keys(params)
      .filter(key => params[key] !== defaultCalcReq[key])
      .reduce((obj, key) => {
        obj[key] = params[key]
        return obj
      }, {})
    const qs = queryString.stringify(filteredParams, { arrayFormat: `bracket` }) as string
    return `?${qs}`
  }

  private updateUrlAsync(newSerializedRequest: string) {
    replace(newSerializedRequest)
  }

  private updateCalcReq = (newData: any) => {
    const newCalcReq = { ...this.state.calcReq, ...newData }
    const totalError = validateCalcReq(newCalcReq)
    const newCalcRes = totalError ? null : this.calculator.calculate(newCalcReq)
    const needTrackGoal = !this.state.didTrackGoal && !totalError && !isSSR()
    const newSerializedRequest = totalError ? this.state.currentSerializedRequest : this.serializeCalcReq(newCalcReq)
    const needUpdateUrl = newSerializedRequest !== this.state.currentSerializedRequest

    this.setState({
      calcReq: newCalcReq,
      calcRes: newCalcRes,
      validation: {
        ...this.state.validation,
        inputsError: null,
        totalError,
      },
      hadInteraction: true,
      didTrackGoal: this.state.didTrackGoal || needTrackGoal,
      currentSerializedRequest: newSerializedRequest,
      isCalculating: false,
    })

    if (needUpdateUrl) {
      this.updateUrlAsync(newSerializedRequest)
    }

    if (needTrackGoal) {
      trackGoal("CREDIT_CALCULATED")
    }
  }

  private getError(): string | null {
    return this.state.validation.inputsError || this.state.validation.totalError
  }

  private onTableViewClick() {
    this.setState({ viewSettings: { ...this.state.viewSettings, showTable: !this.state.viewSettings.showTable } })
  }

  private onChangeIsFullTable() {
    this.setState({ viewSettings: { ...this.state.viewSettings, isFullTable: !this.state.viewSettings.isFullTable } })
  }

  private copyLink() {
    const dummy = document.createElement("input")
    const text = this.props.location.href

    document.body.appendChild(dummy)
    dummy.value = text
    dummy.select()
    document.execCommand("copy")
    document.body.removeChild(dummy)
  }

  private renderPayment(ps: PaymentStatus): JSX.Element[] {
    let res: JSX.Element[] = []
    if (ps.commissionType) {
      const commissionTypeToText = {
        onetime: `Единоразовая комиссия`,
        yearly: `Ежегодная комиссия`,
      }
      res.push(
        <Td colSpan={this.props.needCompareWithRent ? 1 : 3}>
          <HelpContainer text={commissionTypeToText[ps.commissionType]}>
            {formatCurrency(ps.totalPayment, false)}
          </HelpContainer>
        </Td>
      )
      return res
    }
    if (ps.isPrepayment) {
      res.push(
        <Td sx={{ bg: `success` }} colSpan={3}>
          {formatCurrency(ps.totalPayment, false)} (предоплата)
        </Td>
      )
      return res
    }

    if (this.props.needCompareWithRent) {
      res.push(<Td key="total">{formatCurrency(ps.totalPayment, false)}</Td>)
    } else {
      res = res.concat([
        <Td key="debt">{formatCurrency(ps.debtPayment, false)}</Td>,
        <Td key="percent">{formatCurrency(ps.percentPayment, false)}</Td>,
        <Td key="total">{formatCurrency(ps.totalPayment, false)}</Td>,
      ])
    }
    return res
  }

  private renderComparisonWithRent(ps: PaymentStatus, cmp: RentComparisonItem) {
    if (!this.props.needCompareWithRent) {
      return null
    }

    const sx = { bg: cmp.accumulationWon ? `success` : cmp.accumulationFailed ? `danger` : `warning` }

    return [
      <Td sx={sx} key="debtPaid">
        {formatCurrency(cmp.debtPaid, false)}
      </Td>,
      <Td sx={sx} key="accumulated">
        {formatCurrency(cmp.totalAccumulated, false)}
      </Td>,
      <Td sx={sx} key="house_price">
        {formatCurrency(cmp.housePrice, false)}
      </Td>,
      <Td sx={sx} key="rent_price">
        {formatCurrency(cmp.rentPayment, false)}
      </Td>,
    ]
  }

  private paymentStatusDate(ps: PaymentStatus): string {
    return formatMonth(ps.month, ps.year)
  }

  private renderReportTable(): JSX.Element {
    const calcRes = this.state.calcRes
    let lastShownYear = null
    return (
      <ResponsiveTableContainer sx={{ mt: 2 }}>
        <table>
          <thead>
            <tr>
              <Th rowSpan={2}>№</Th>
              <Th rowSpan={2}>Дата</Th>
              <Th colSpan={this.props.needCompareWithRent ? 1 : 3} rowSpan={this.props.needCompareWithRent ? 2 : 1}>
                Платеж, руб
              </Th>
              <Th rowSpan={2}>Остаток долга, руб</Th>
              {this.props.needCompareWithRent && [
                <Th key="paid_for_house" rowSpan={2}>
                  Выплачено за квартиру, руб
                </Th>,
                <Th key="alternative" colSpan={3}>
                  Альтернатива ипотеке: накопление
                </Th>,
              ]}
            </tr>
            <tr>
              {!this.props.needCompareWithRent && [
                <Th key="debt">Долг</Th>,
                <Th key="percent">Проценты</Th>,
                <Th key="total">Всего</Th>,
              ]}
              {this.props.needCompareWithRent && [
                <Th key="accumulated">Накоплено, руб</Th>,
                <Th key="house_price">Стоимость квартиры, руб</Th>,
                <Th key="rent_price">Стоимость аренды, руб</Th>,
              ]}
            </tr>
          </thead>
          <tbody>
            {calcRes.paymentStatuses
              .map((ps, i) => [ps, calcRes.rentComparison && calcRes.rentComparison.items[i]])
              .filter((pair, i) => {
                const ps = pair[0] as PaymentStatus
                if (this.state.viewSettings.isFullTable || ps.isPrepayment || ps.commissionType) {
                  return true
                }
                if (ps.year === lastShownYear && i !== calcRes.paymentStatuses.length - 1) {
                  return false
                }
                lastShownYear = ps.year
                return true
              })
              .map((pair, i) => {
                const ps = pair[0] as PaymentStatus
                return (
                  <tr key={i}>
                    <Td>{ps.paymentNumber + 1}</Td>
                    <Td>{this.paymentStatusDate(ps)}</Td>
                    {this.renderPayment(ps)}
                    <Td>{formatCurrency(ps.debt, false)}</Td>
                    {this.renderComparisonWithRent(ps, pair[1] as RentComparisonItem)}
                  </tr>
                )
              })}
          </tbody>
        </table>
      </ResponsiveTableContainer>
    )
  }

  private canRenderChart(): boolean {
    if (this.props.needCompareWithRent) {
      return false // TODO
    }

    return true
  }

  private renderChart(): JSX.Element {
    if (!this.canRenderChart()) {
      return null
    }

    if (!this.state.hadInteraction) {
      return null // Speed up page loading.
    }

    if (isSSR()) {
      return null
    }

    return (
      <ErrorBoundary name="график платежей">
        <Suspense fallback={<div>График платежей загружается...</div>}>
          <Chart calcRes={this.state.calcRes} />
        </Suspense>
      </ErrorBoundary>
    )
  }

  private onChangePrepayments = (v: Prepayment[]) => this.updateCalcReq({ prepayments: v })
  private onShowChartButtonClick = () => this.setState({ hadInteraction: true })

  private renderReport(): JSX.Element {
    const { calcReq, calcRes, isCalculating } = this.state

    if (isCalculating) {
      return (
        <Row center="xs">
          <Col xs={3}>
            <Loader />
          </Col>
        </Row>
      )
    }

    const isMonthlyPaymentReducedByPrepayment =
      this.state.calcRes.prepaymentsResult && this.state.calcRes.prepaymentsResult.monthlyPaymentReducedTo !== null

    return (
      <div sx={{ ml: 3 }}>
        {this.props.needPrepayments && (
          <PrepaymentsController
            onChange={this.onChangePrepayments}
            prepayments={this.state.calcReq.prepayments}
            onStartCalculating={this.onStartCalculating}
          />
        )}

        {!isMonthlyPaymentReducedByPrepayment && (
          <p>
            <b>Месячный платеж</b>:{" "}
            {calcReq.isAnnuity
              ? formatCurrency(calcRes.monthlyPayment.total)
              : `от ${formatCurrency(calcRes.monthlyPayment.from)} до ${formatCurrency(calcRes.monthlyPayment.to)}`}
          </p>
        )}
        {this.state.calcReq.needCommissions && !isMonthlyPaymentReducedByPrepayment && (
          <p>
            <b>Усредненный месячный платеж с учетом комиссий</b>:{" "}
            {calcReq.isAnnuity
              ? formatCurrency(calcRes.monthlyPayment.totalAmortized)
              : `от ${formatCurrency(calcRes.monthlyPayment.amortizedFrom)} до ${formatCurrency(
                  calcRes.monthlyPayment.amortizedTo
                )}`}
          </p>
        )}
        {isMonthlyPaymentReducedByPrepayment && (
          <p>
            <b>Месячный платеж</b>: {formatCurrency(this.state.calcRes.prepaymentsResult.monthlyPaymentReducedTo)}
            {" (было "}
            {formatCurrency(this.state.calcRes.prepaymentsResult.originalAnnuityMonthlyPayment)}
            {")"}
          </p>
        )}
        {this.state.calcRes.prepaymentsResult && this.state.calcRes.prepaymentsResult.termReducedByMonths !== null && (
          <p>
            <b>Срок кредита сокращен на</b>: {`${this.state.calcRes.prepaymentsResult.termReducedByMonths} мес`}
          </p>
        )}
        {this.state.calcRes.prepaymentsResult ? (
          [
            <p key="overPaymentBefore">
              <b>Переплата после досрочных платежей</b>:{" "}
              {`${formatCurrency(calcRes.overPayment.total)} (${Math.round(
                calcRes.overPayment.percent
              )}% от суммы кредита)`}
            </p>,
            <p key="overPaymentAfter">
              <b>Переплата до досрочных платежей</b>:{" "}
              {`${formatCurrency(calcRes.prepaymentsResult.originalOverPayment.total)} (${Math.round(
                calcRes.prepaymentsResult.originalOverPayment.percent
              )}% от суммы кредита)`}
            </p>,
          ]
        ) : (
          <p>
            <b>Переплата</b>:{" "}
            {`${formatCurrency(calcRes.overPayment.total)} (${Math.round(
              calcRes.overPayment.percent
            )}% от суммы кредита)`}
          </p>
        )}
        {this.props.needInflation && (
          <p>
            <b>Переплата с учетом инфляции</b>:{" "}
            {`${formatCurrency(calcRes.overPayment.totalWithInflation)} (${Math.round(
              calcRes.overPayment.percentWithInflation
            )}% от суммы кредита)`}
          </p>
        )}
        {this.props.needCompareWithRent && [
          <p key="amortizedServicesCost">
            <b>Амортизированная стоимость ремонта и услуг ЖКХ в месяц:</b>{" "}
            {formatCurrency(calcRes.rentComparison.avgMonthlyHouseServicePayment)}
          </p>,
          <p key="whatsBetter" sx={{ bg: `primaryLight`, borderRadius: 2, padding: 2, color: `white` }}>
            {calcRes.rentComparison.rentIsBetterAfterMonths < 0
              ? `Ипотека выгоднее накопления и аренды!`
              : `Копить и арендовать выгоднее! Накопить на квартиру можно уже на ${
                  calcRes.rentComparison.rentIsBetterAfterMonths
                }-м месяце (${Math.round(calcRes.rentComparison.rentIsBetterAfterMonths / 12)} ${pluralize(
                  Math.round(calcRes.rentComparison.rentIsBetterAfterMonths / 12),
                  "год",
                  "года",
                  "лет"
                )}).`}
          </p>,
        ]}
        <Button onClick={this.onTableViewClick} sx={{ mr: 2, mt: 2 }} variant="secondary">
          {this.state.viewSettings.showTable ? `Скрыть` : `Показать`} таблицу платежей
        </Button>
        {this.state.viewSettings.showTable && (
          <Button onClick={this.onChangeIsFullTable} sx={{ mr: 2, mt: 2 }} variant="secondary">
            {this.state.viewSettings.isFullTable ? `Меньше` : `Больше`} строк
          </Button>
        )}
        {!this.state.hadInteraction && this.canRenderChart() && (
          <Button sx={{ mt: 2 }} onClick={this.onShowChartButtonClick} variant="secondary">
            Показать график платежей
          </Button>
        )}
        {this.state.viewSettings.showTable && this.renderReportTable()}
        {this.renderChart()}
      </div>
    )
  }

  render() {
    const error = this.getError()
    const report = error ? null : this.renderReport()
    return (
      <div>
        <Row sx={formRowSx}>
          <FormColumn xs={12} md={4}>
            <CalculatorForm
              onChange={this.updateCalcReq}
              onInvalidInput={this.onInvalidInput}
              onStartCalculating={this.onStartCalculating}
              calcReq={this.state.calcReq}
              needInflation={this.props.needInflation}
            />
            <hr />
          </FormColumn>
          <Col xs={12} md={8} lg={8}>
            {error ? (
              <p>
                <ErrorBox>{error}</ErrorBox>
              </p>
            ) : (
              report
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sx={{ mt: 2 }}>
            <div>
              <ReactTooltip trigger={["click"]} placement="right" overlay="Ссылка скопирована!">
                <IconedLink href="#" onClick={this.copyLink}>
                  <FaCopy /> Скопировать ссылку на этот расчет
                </IconedLink>
              </ReactTooltip>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}
