/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Row, Col } from "react-flexbox-grid"
import Select from "react-select"

import { pluralize, selectNoOptionsText, selectScreenReaderStatus } from "utils/lang"
import theme from "utils/theme"

export type Term = {
  years: number
  additionalMonths: number
}

type Props = {
  name: string
  initialTerm: Term
  onTermChange: (newTerm: Term) => void
}

type State = {
  term: Term
  isCustomSelectionActive: boolean
  isCustomYearMenuOpen: boolean
  customYearOpt: Option
  customMonthOpt: Option
}

const range = (start: number, end: number): number[] => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx)
}

type Option = {
  value: number
  label: string
}

const yearToOption = (year: number): Option => ({
  value: year,
  label: `${year} ${pluralize(year, "год", "года", "лет")}`,
})
const monthToOption = (month: number): Option => ({
  value: month,
  label: `${month} ${pluralize(month, "месяц", "месяца", "месяцев")}`,
})

const popularYears = [5, 10, 15, 20, 25, 30]
const needCustomYear = -1
const optionOther = { value: needCustomYear, label: `Другой срок` }
const popularYearOptions = [optionOther].concat(popularYears.map(yearToOption))

const allYears = range(0, 30)
const allYearOptions = allYears.map(yearToOption)

const allMonths = range(0, 11)
const allMonthOptions = allMonths.map(monthToOption)

const popularYearSelectedCustomStyling = {
  singleValue: (provided, state) => ({ ...provided, color: theme.colors.textMuted }),
}

export default class TermInput extends React.PureComponent<Props, State> {
  private initialPopularYearOpt: Option

  constructor(props: Props) {
    super(props)
    const popularYearOpt =
      this.props.initialTerm.additionalMonths === 0 &&
      popularYearOptions.find(opt => opt.value === this.props.initialTerm.years)
    this.initialPopularYearOpt = popularYearOpt || popularYearOptions.find(opt => opt.value === needCustomYear)
    const customYearOpt =
      popularYearOpt ||
      allYearOptions.find(opt => opt.value === this.props.initialTerm.years) ||
      allYearOptions[Math.round(allYearOptions.length / 2)]
    const customMonthOpt =
      allMonthOptions.find(opt => opt.value === this.props.initialTerm.additionalMonths) || allMonthOptions[0]

    this.state = {
      term: { ...this.props.initialTerm },
      isCustomSelectionActive: !popularYearOpt,
      isCustomYearMenuOpen: false,
      customYearOpt,
      customMonthOpt,
    }

    this.onChangePopularYear = this.onChangePopularYear.bind(this)
    this.onChangeCustomYear = this.onChangeCustomYear.bind(this)
    this.onChangeCustomMonth = this.onChangeCustomMonth.bind(this)
  }

  private onChangePopularYear(v: Option) {
    if (v.value === needCustomYear) {
      this.setState({ isCustomSelectionActive: true, isCustomYearMenuOpen: true })
      this.props.onTermChange({
        years: this.state.customYearOpt.value,
        additionalMonths: this.state.customMonthOpt.value,
      })
      return
    }
    this.setState({ isCustomSelectionActive: false })
    this.props.onTermChange({ years: v.value, additionalMonths: 0 })
  }

  private onChangeCustomYear(v: Option) {
    const stateDiff = { isCustomYearMenuOpen: false, customYearOpt: v, customMonthOpt: this.state.customMonthOpt }
    if (v.value === 0 && this.state.customMonthOpt.value === 0) {
      stateDiff.customMonthOpt = allMonthOptions[1]
    }
    this.setState(stateDiff)
    this.props.onTermChange({ years: stateDiff.customYearOpt.value, additionalMonths: stateDiff.customMonthOpt.value })
  }

  private onChangeCustomMonth(v: Option) {
    const stateDiff = { isCustomYearMenuOpen: false, customYearOpt: this.state.customYearOpt, customMonthOpt: v }
    if (v.value === 0 && this.state.customYearOpt.value === 0) {
      stateDiff.customYearOpt = allYearOptions[1]
    }
    this.setState(stateDiff)
    this.props.onTermChange({ years: stateDiff.customYearOpt.value, additionalMonths: stateDiff.customMonthOpt.value })
  }

  render() {
    const customSelectors = this.state.isCustomSelectionActive && (
      <Row sx={{ mt: 2 }}>
        <Col xs={6}>
          <Select
            menuIsOpen={this.state.isCustomYearMenuOpen || undefined}
            value={this.state.customYearOpt}
            options={allYearOptions}
            onChange={this.onChangeCustomYear}
            noOptionsMessage={selectNoOptionsText}
            screenReaderStatus={selectScreenReaderStatus}
          />
        </Col>
        <Col xs={6}>
          <Select
            value={this.state.customMonthOpt}
            options={allMonthOptions}
            onChange={this.onChangeCustomMonth}
            noOptionsMessage={selectNoOptionsText}
            screenReaderStatus={selectScreenReaderStatus}
          />
        </Col>
      </Row>
    )
    return (
      <div>
        <div>
          <Select
            styles={this.state.isCustomSelectionActive ? popularYearSelectedCustomStyling : undefined}
            blurInputOnSelect
            defaultValue={this.initialPopularYearOpt}
            options={popularYearOptions}
            onChange={this.onChangePopularYear}
            noOptionsMessage={selectNoOptionsText}
            screenReaderStatus={selectScreenReaderStatus}
          />
        </div>
        {customSelectors}
      </div>
    )
  }
}
