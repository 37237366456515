import { Month } from "./month-input"

const today = new Date()

export const todayMonth: Month = {
  num: today.getMonth() + 1,
  year: today.getFullYear(),
}

export default today
